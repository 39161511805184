<script setup>
  import { countdownTimer } from './compontent/stepsStatus'
  import orderOperation from './compontent/operation'
  import orderStepStatus from './compontent/order-step-status'
  import orderStatusInfo from './compontent/order-status'
  import addressInfo from './compontent/address-info'
  import orderList from '@/views/order/compontent/order-list-new'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, provide } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    loading: false,
    orderId: '', // 订单id
    orderInfo: {
      orderNo: ''
    }, // 订单信息
    itemDetails: [], // 订单商品列表
    orderModifyRecordList: [], // 订单状态列表
    orderSummary: {}, // 订单汇总信息
    orderStatus: '' // 前端可使用的订单状态
  })

  const deliveredData = ref('')
  const formattedTime1 = ref('')
  const formattedTime2 = ref('')

  // 查询订单追踪|包含订单详情
  const getOrderTrackInfo = () => {
    data.loading = true
    api.order
      .ordersTrackInfo(data.orderId)
      .then((res) => {
        console.log('查询订单追踪|包含订单详情', res)
        if (res && res.customerDetails && res.customerDetails.orderNo) {
          data.orderInfo = res.customerDetails
          data.itemDetails = res.itemDetails
          data.orderModifyRecordList = res.orderModifyRecordList
          data.orderSummary = res.orderSummary
          confirmationStatus()
          if (data.orderInfo.orderStatus === 'Delivered') {
            handleDelivered()
          }
        }
      })
      .catch((error) => {
        if (!error.error) {
          proxy.$toast(error)
        }
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 判断订单状态-前端使用
  const confirmationStatus = () => {
    const { orderStatus, countDownTime } = data.orderInfo
    if ((orderStatus === 'NOPAID' || orderStatus === 'PAYING' || orderStatus === 'PAYMENT_FAILED') && Number(countDownTime) > 0) {
      data.orderStatus = 'PendingPayment'
      countdownTimer(data.orderInfo.countDownTime, handleTimeUpdate)
      // 步骤条包含NOPAID PAYING
    } else if (orderStatus === 'CLOSED') {
      // 步骤条包含NOPAID Closed
      data.orderStatus = 'OrderClosed'
    } else {
      // 步骤条包含NOPAID UNDISTRIBUTED DISTRIBUTED RECEIPTED
      data.orderStatus = 'OrderInProgress'
    }
  }

  // 处理收货时间
  const handleDelivered = () => {
    const list = data.orderModifyRecordList.filter((item) => item.orderStatus === 'NOPAID')
    console.log(list, 'list')
    if (list.length > 0) {
      const date = new Date(list[0].createdDate)
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      deliveredData.value = date.toLocaleDateString('en-US', options)
    }
  }

  // 处理倒计时展示
  const handleTimeUpdate = (formattedTime) => {
    formattedTime1.value = formattedTime
    const [hours, minutes, seconds] = formattedTime.split(':')
    const formattedTimeNew = `${hours} hrs ${minutes} mins ${seconds} secs`
    formattedTime2.value = formattedTimeNew
  }

  // 更新订单状态
  const updataStatus = (obj) => {
    console.log('更新状态', obj)
    if (data.orderInfo.orderStatus) {
      data.orderInfo.orderStatus = obj.status
    }
    if (obj.optType && obj.optType === 'cancel') {
      getOrderTrackInfo()
    }
  }

  // 跳转用户页面
  const toUserInfo = () => {
    userStore.setUserShowPage({ page: 'orderList' })
    router.replace({ name: 'USERINFO' })
  }

  // 跳转首页
  const toHome = () => {
    router.replace({ name: 'home' })
  }

  const toHelpCenter = () => {
    userStore.setUserShowPage({ page: 'userHelp', selectType: 'userHelp' })
    proxy.$router.push({
      path: '/USERINFO'
    })
  }

  // 初始化
  const init = () => {
    if (JLTools.isNull(data.orderId)) return
    getOrderTrackInfo()
  }

  onMounted(() => {
    // console.info(useRoute().params)
    data.orderId = useRoute().params.orderId || ''
    init()
  })

  provide('formattedTime1', formattedTime1) // 倒计时展示
  provide('formattedTime2', formattedTime2) // 倒计时展示
  provide('deliveredData', deliveredData) // 收货时间
</script>

<template>
  <div class="content" v-loading="data.loading">
    <template v-if="data.orderInfo.orderNo">
      <div class="page-content">
        <div class="rowBC head-box">
          <div class="breadcrumb-box rowSC">
            <span>{{ $t('Home') }}</span>
            <img class="img" src="~img/down_icon.svg" />
            <!-- <JLImg class="back-img" :src="require('@/assets/imgs/order/ArrowLeft.png')" @click="toHome"></JLImg> -->
            <span class="span-cup" @click="toUserInfo">{{ $t('Orders') }}</span>
          </div>
          <orderOperation :orderInfo="data.orderInfo" :orderStatus="data.orderStatus" @updataStatus="updataStatus"></orderOperation>
        </div>
        <div class="order-status-box">
          <order-step-status
            v-if="data.orderModifyRecordList.length"
            :orderInfo="data.orderInfo"
            :orderStatus="data.orderStatus"
            :orderModifyRecordList="data.orderModifyRecordList"
            data-remark="订单步骤条"
          />
          <order-status-info class="order-info-card" data-remark="订单状态信息" :orderInfo="data.orderInfo" :orderSummary="data.orderSummary"></order-status-info>
        </div>
        <order-list v-if="data.itemDetails.length > 0" :itemDetails="data.itemDetails" :orderSummary="data.orderSummary"></order-list>
        <div class="order-info-card" data-remark="其他信息">
          <address-info :orderInfo="data.orderInfo" />
        </div>
      </div>
      <div class="hint-text">
        Have any concerns? Send us an email via
        <a href="mailto: info@haier.com.pk"> info@haier.com.pk </a>
        <!-- <span @click="toHelpCenter">contact info@haier.com.pk</span> -->
      </div>
    </template>
  </div>
</template>

<style lang="less" scoped>
  .content {
    width: var(--minWidth);
    max-width: 900px;
    margin: 0 auto;
    min-height: 400px;
    .hint-text {
      padding: 24px 0 40px;
      font-size: 14px;
      line-height: 20px;
      span {
        color: var(--Zcolor);
        cursor: pointer;
      }
      a {
        color: var(--Zcolor);
      }
    }
  }
  .page-content {
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    min-height: 400px;
    .head-box {
      padding: 14px 24px;
      border-bottom: 1px solid #e4e7e9;
    }
    .order-status-box {
      padding: 40px 16px 0 16px;
    }
  }
  .breadcrumb-box {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    .span-cup {
      cursor: pointer;
    }
    .img {
      margin: 9px;
      width: 12px;
      height: 12px;
      transform: rotate(270deg);
    }
    .back-img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      cursor: pointer;
    }
  }
</style>
