<script setup>
  import { transformString } from '@/views/order/compontent/handlerOrder'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    addressInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })
</script>

<template>
  <div class="address-info-box rowSS">
    <div class="order_user_card" data-remark="地址信息">
      <div class="title">{{ $t('Consignee Information') }}</div>
      <div class="rowSC">
        <div class="item">
          <div>{{ $t('Consignee') }}:</div>
          <div>{{ props.orderInfo.consignee }}</div>
        </div>
        <div class="item">
          <div>{{ $t('Phone Number') }}:</div>
          <div>+92 {{ props.orderInfo.mobile }}</div>
        </div>
      </div>
      <div class="item">
        <div>{{ $t('Address') }}:</div>
        <div class="text_hide3">{{ props.orderInfo.deliveryAddress }}</div>
      </div>
    </div>
    <div class="order_user_card" data-remark="购买信息">
      <div class="title">{{ $t('Customer Information') }}</div>
      <div class="item">
        <div>{{ $t('Customer') }}:</div>
        <div v-if="props.orderInfo.nickname">{{ props.orderInfo.nickname }}</div>
      </div>
    </div>
    <div class="order_user_card" data-remark="支付信息">
      <div>
        <div class="title">{{ $t('Payment Information') }}</div>
        <div>
          <div class="item">
            <div>Payment Method:</div>
            <div>{{ transformString(props.orderInfo.paymentMethod) }}</div>
          </div>
          <div class="item" v-if="props.orderInfo.transactionId">
            <div>{{ $t('transactionID') }}:</div>
            <div>{{ props.orderInfo.transactionId }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .address-info-box {
    padding: 24px 0;
    margin: 0 16px;
    border-top: 1px solid #dcdfe6;
    box-sizing: border-box;
    .order_user_card {
      width: 33.333%;
      min-height: 150px;
      border-left: 1px dashed #e1e1e1;
      padding: 16px;
      &:first-child {
        border-left: 0px;
      }
      .title {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
      }
      .item {
        width: 100%;
        line-height: 20px;
        margin-top: 10px;
        div {
          color: #999;
          &:first-child {
            margin-right: 10px;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
</style>
